@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: "Poppins 400";
    font-weight: 400;
    font-display: swap;
    src: url(../public/fonts/Poppins-Regular.ttf) format("woff2");
  }
  @font-face {
    font-family: "Poppins 500";
    font-weight: 500;
    font-display: swap;
    src: url(../public/fonts/Poppins-Medium.ttf) format("woff2");
  }

  @font-face {
    font-family: "Poppins 600";
    font-weight: 500;
    font-display: swap;
    src: url(../public/fonts/Poppins-SemiBold.ttf) format("woff2");
  }
  @font-face {
    font-family: "Poppins 700";
    font-weight: 500;
    font-display: swap;
    src: url(../public/fonts/Poppins-Bold.ttf) format("woff2");
  }
  @font-face {
    font-family: "Poppins 800";
    font-weight: 500;
    font-display: swap;
    src: url(../public/fonts/Poppins-ExtraBold.ttf) format("woff2");
  }
}

/* html {
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
} */

.thankyou {
  min-height: calc(100vh - 80px);
}

.header-subtitle {
  color: var(--primary-2);
  background-color: #ffffff;
}

#bundle-card::-webkit-scrollbar-track {
  background: transparent;
  box-shadow: inset 0 0 5px #dedede;
  border-left: 2px solid transparent;
  border-right: 2px solid transparent;
}
#bundle-card::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
#bundle-card::-webkit-scrollbar-thumb {
  background: #bdbdbd;
  border-radius: 5px;
}

/* #bundle-card {
  scrollbar-color: #bdbdbd transparent !important;
} */

.doller-icon p {
  font-size: 30px !important;
  color: #131313 !important;
}

.grecaptcha-badge {
  width: 256px;
  height: 60px;
  position: fixed;
  visibility: hidden;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
}
